import {
  NO_INFO,
  CATS_ALLOWED,
  NO_CATS_ALLOWED,
  LARGE_DOGS_ALLOWED,
  NO_LARGE_DOGS_ALLOWED,
  SMALL_DOGS_ALLOWED,
  NO_SMALL_DOGS_ALLOWED,
  WATER_AND_SEWER,
  ELECTRICITY,
  GAS,
  TRASH,
  INTERNET_CABLE,
  HEATING_AIR_CONDITIONING,
  IN_UNIT_WASHER_DRYER,
  DISHWASHER,
  MICROWAVE,
  STAINLESS_STEEL_APPLIANCES,
  HARDWOOD_FLOORING,
  INCLUDED_UTILITIES,
  INTERIOR_AMENITIES,
  OUTDOOR_AMENITIES,
  COMMUNITY_AMENITIES,
  CENTRAL_AC_HEATING,
  WALK_IN_CLOSETS,
  HIGH_SPEED_INTERNET,
  FIREPLACE,
  PATIO,
  BBQ_GRILLS_OR_PICNIC_AREA,
  ROOFTOP_DECK,
  PARKING_SPACE,
  GYM,
  SWIMMING_POOL,
  COMMUNITY_CLUBHOUSE,
  PLAYGROUND_OR_DOG_PARK,
  LAUNDRY_FACILITIES,
  PARKING_OR_GARAGE,
  SECURITY_OR_CONTROLLED_ACCESS,
  EN,
  UNAVAILABLE,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { useLocalization } from '~/hooks/useLocalization';
import { User } from '~/state/mainAppState';
import { PresentCompanyUnitListingDetail, TenantHydratedUnitListingDto, Feature } from '~/types/Unit';
const { currentLanguage, t } = useLocalization();
export class UnitListingDetailPresenter extends Presenter<PresentCompanyUnitListingDetail> {
  protected createModel(state: User): PresentCompanyUnitListingDetail {
    const detail = state.companyUnitListingDetail;

    return {
      id: detail?.id ?? '',
      name: detail?.title ?? NO_INFO,
      price: currency(detail?.rentalPrice ?? 0),
      address: detail?.singleLineAddress ?? NO_INFO,
      bedrooms: detail?.beds?.toString() ?? NO_INFO,
      bathrooms: detail?.baths?.toString() ?? NO_INFO,
      area: detail?.unitSizeSqft?.toString() ?? NO_INFO,
      description: detail?.description ?? NO_INFO,
      petPolicy: this.getPetPolicy(detail!),
      listed: this.getListed(detail?.listed?.toString() ?? ''),
      images:
        detail?.images?.map((image) => ({
          url: image?.signedUrl ? '/api' + image.signedUrl : '',
          title: image?.fileId ?? '',
        })) ?? [],
      features: this.getFeatures(detail!),
    };
  }

  private getFeatures(detail: TenantHydratedUnitListingDto) {
    const includedUtilities: Feature = {
      name: INCLUDED_UTILITIES,
      children: [],
    };
    const interiorAmenities: Feature = {
      name: INTERIOR_AMENITIES,
      children: [],
    };
    const outdoorAmenities: Feature = {
      name: OUTDOOR_AMENITIES,
      children: [],
    };
    const communityAmenities: Feature = {
      name: COMMUNITY_AMENITIES,
      children: [],
    };

    detail?.amenities?.forEach((amenity) => {
      if (amenity.avalability === UNAVAILABLE) {
        return;
      }

      if (
        [
          GYM,
          SWIMMING_POOL,
          COMMUNITY_CLUBHOUSE,
          PLAYGROUND_OR_DOG_PARK,
          LAUNDRY_FACILITIES,
          PARKING_OR_GARAGE,
          SECURITY_OR_CONTROLLED_ACCESS,
        ].includes(amenity.name!)
      ) {
        communityAmenities.children?.push(amenity.name!);
      }

      if ([PATIO, BBQ_GRILLS_OR_PICNIC_AREA, ROOFTOP_DECK, PARKING_SPACE].includes(amenity.name!)) {
        outdoorAmenities.children?.push(amenity.name!);
      }

      if ([WATER_AND_SEWER, ELECTRICITY, GAS, TRASH, INTERNET_CABLE, HEATING_AIR_CONDITIONING]?.includes(amenity.name!)) {
        includedUtilities.children?.push(amenity.name!);
      }

      if (
        [
          IN_UNIT_WASHER_DRYER,
          DISHWASHER,
          MICROWAVE,
          STAINLESS_STEEL_APPLIANCES,
          HARDWOOD_FLOORING,
          CENTRAL_AC_HEATING,
          WALK_IN_CLOSETS,
          HIGH_SPEED_INTERNET,
          FIREPLACE,
        ].includes(amenity.name!)
      ) {
        interiorAmenities.children?.push(amenity.name!);
      }
    });
    return [includedUtilities, interiorAmenities, outdoorAmenities, communityAmenities];
  }

  private getListed(data: string) {
    if (!data) {
      return NO_INFO;
    }

    if (currentLanguage() === EN) {
      const date = new Date(data);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
    return data;
  }

  private getPetPolicy(detail: TenantHydratedUnitListingDto) {
    const strArr = [];
    const petPolicy = detail?.petPolicy;
    if (petPolicy?.catsAllowed) {
      strArr.push(CATS_ALLOWED);
    } else {
      strArr.push(NO_CATS_ALLOWED);
    }

    if (petPolicy?.largeDogsAllowed) {
      strArr.push(LARGE_DOGS_ALLOWED);
    } else {
      strArr.push(NO_LARGE_DOGS_ALLOWED);
    }

    if (petPolicy?.smallDogsAllowed) {
      strArr.push(SMALL_DOGS_ALLOWED);
    } else {
      strArr.push(NO_SMALL_DOGS_ALLOWED);
    }

    return strArr.join(', ');
  }
}
