import { useParams, useNavigate } from '@solidjs/router';
import { For, createEffect, createMemo, Accessor, Show } from 'solid-js';
import IconBathroom from '~/assets/images/home/bathroom.svg';
import IconArea from '~/assets/images/home/ft.svg';
import IconRoom from '~/assets/images/home/room.svg';
import IconAc from '~/assets/images/property/ac.svg';
import IconDate from '~/assets/images/property/date.svg';
import IconOutdoor from '~/assets/images/property/outdoor.svg';
import IconParking from '~/assets/images/property/parking.svg';
import IconPet from '~/assets/images/property/pet.svg';
import IconUtilities from '~/assets/images/property/utilities.svg';
import {
  CONTACT_MANAGER,
  PETS,
  AC,
  PARKING,
  UTILITIES_INCLUDED,
  OUTDOOR,
  LISTED,
  SQFT,
  MONTH,
  SUBMIT_A_RENTAL_APPLICATION,
  OVERVIEW,
  HOME_HIGHLIGHTS,
  FACTS_AND_FEATURES,
  LOCAL_INFORMATION,
  HOME,
  PROPERTY_DETAIL,
  NA,
} from '~/assets/strings';
import GoogleMap from '~/components/common/GoogleMap';
import { Button } from '~/components/common/buttons';
import { AreaBreadcrumb } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { useTitle } from '~/hooks/useTitle';
import { PhotoWall } from '~/pages/property-detail/photo-wall';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { UnitListingDetailPresenter } from '~/presenters/UnitListingDetailPresenter';
import { GetCompanyUnitListingDetailUseCase } from '~/use-cases/company-unit-listing/getCompanyUnitListingDetailUseCase';
import { CreateRentalApplicationUseCase } from '~/use-cases/rental-applications/createRentalApplicationUseCase';

interface HomeHighlight {
  icon: string;
  title: string;
  data?: string;
}

export default function PropertyDetail() {
  const navigate = useNavigate();
  const { t } = useLocalization();
  useTitle({ title: t(PROPERTY_DETAIL) });
  const { id } = useParams();
  const { execute: getListingDetail } = useUseCase(GetCompanyUnitListingDetailUseCase);
  const { model: listingDetail } = usePresenter(UnitListingDetailPresenter);
  const { execute: createRentalApplication, didSucceed, isLoading } = useUseCase(CreateRentalApplicationUseCase);
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);

  createEffect(() => {
    getListingDetail(id);
  });

  const onCreateRentalApplication = async () => {
    await createRentalApplication();
    if (didSucceed()) {
      navigate(`${presentableRentalApplication()?.url}/applicant-information?unitId=${id}`);
    }
  };

  const highlights: Accessor<HomeHighlight[]> = createMemo(() => {
    return [
      {
        icon: IconPet,
        title: t(PETS),
        data: listingDetail()?.petPolicy,
      },
      {
        icon: IconAc,
        title: t(AC),
        data: CONTACT_MANAGER,
      },
      {
        icon: IconParking,
        title: t(PARKING),
        data: CONTACT_MANAGER,
      },
      {
        title: t(UTILITIES_INCLUDED),
        data: CONTACT_MANAGER,
        icon: IconUtilities,
      },
      {
        title: t(OUTDOOR),
        icon: IconOutdoor,
        data: CONTACT_MANAGER,
      },
      {
        title: t(LISTED),
        icon: IconDate,
        data: listingDetail()?.listed,
      },
    ];
  });

  return (
    <div class="flex w-full flex-col items-center">
      <div class="flex h-sixty w-full items-center justify-center bg-border-level01">
        <div class="flex w-max-container-width items-center ">
          <AreaBreadcrumb showBack={true} data={[{ name: t(HOME), path: '/company-website' }, { name: t(PROPERTY_DETAIL) }]} />
        </div>
      </div>

      <div class="mt-[50px]  w-max-container-width">
        <PhotoWall data={listingDetail()?.images || []} />
        <div class="flex gap-4">
          <div class="w-[875px]">
            <div class="mt-8 flex gap-4">
              <div class="flex-1 border-b border-[#E5E6E7] pb-8">
                <div>
                  <span class="text-[32px] font-semibold">{listingDetail()?.price}</span> <span class="text-[#5E6987]">/{MONTH}</span>
                </div>

                <div class="mt-[10px] text-[40px] font-semibold">{listingDetail()?.name}</div>
                <div class="mt-[2px] text-[#5E6987]">{listingDetail()?.address}</div>
                <div class="mt-[10px] flex items-center gap-4 text-sm text-[#4F5971]">
                  <div class="flex items-center gap-1">
                    <img src={IconRoom} alt="" />
                    {listingDetail()?.bedrooms}
                  </div>
                  <div class="flex items-center gap-1">
                    <img src={IconBathroom} alt="" />
                    {listingDetail()?.bathrooms}
                  </div>

                  <div class="flex items-center gap-1">
                    <img src={IconArea} alt="" />
                    {listingDetail()?.area} {t(SQFT)}
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-[42px] w-[875px]">
              <div class="text-[32px] font-semibold text-[#222735]"> {t(OVERVIEW)}</div>
              <div class="mt-4 text-[14px] leading-7 text-[#222735] ">{listingDetail()?.description}</div>

              <div class="mt-[26px] rounded-xl border border-[#E8E9EA]">
                <div class="border-b border-[#E8E9EA] px-6 py-[18px] text-[18px] font-semibold text-[#222735]">{t(HOME_HIGHLIGHTS)}</div>
                <div class="grid grid-cols-2 gap-4 px-6 py-[20px]">
                  <For each={highlights()}>
                    {(item) => (
                      <div class="flex items-center">
                        <div class="flex w-[150px] items-center gap-2">
                          <img src={item.icon} alt="" />
                          <div class="text-sm text-[#7F89A2]">{item.title}</div>
                        </div>

                        <div class="flex-1 text-left">{item.data}</div>
                      </div>
                    )}
                  </For>
                </div>
              </div>

              <div class="mt-[26px] rounded-xl border border-[#E8E9EA]">
                <div class="border-b border-[#E8E9EA] px-6 py-[18px] text-[18px] font-semibold text-[#222735]">{t(FACTS_AND_FEATURES)}</div>
                <div class="flex flex-col gap-6 p-[22px]">
                  <For each={listingDetail()?.features}>
                    {(item) => (
                      <div>
                        <div class="text-sm font-semibold">{t(item.name)}</div>

                        <Show when={item?.children?.length} fallback={<div class="px-6 py-[20px]  text-sm ">{t(NA)}</div>}>
                          <div class="grid grid-cols-3 gap-4 px-6 py-[20px]">
                            <For each={item.children}>
                              {(child) => (
                                <div class="flex items-center gap-2">
                                  <div class="size-1 rounded-full bg-[#222735]" />
                                  <div class="text-sm text-[#222735]">{t(child)}</div>
                                </div>
                              )}
                            </For>
                          </div>
                        </Show>
                      </div>
                    )}
                  </For>
                </div>
              </div>

              <div class="mt-[26px] h-[400px]">
                <div class="mb-2 text-[32px] font-semibold">{t(LOCAL_INFORMATION)}</div>
                <Show when={listingDetail()?.address}>
                  <GoogleMap address={listingDetail()?.address ?? ''} />
                </Show>
              </div>
            </div>
          </div>

          <div class="sticky  top-1/4 mt-4 h-fit w-[429px] rounded-lg border border-[#E5E6E7] p-6 ">
            <Button
              loading={isLoading()}
              onClick={onCreateRentalApplication}
              class="w-full bg-primary-color text-center text-white [&>span]:w-full">
              {' '}
              {t(SUBMIT_A_RENTAL_APPLICATION)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
